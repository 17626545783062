:root {
  --primary: #d62246;
  --secondary: #0c6291;
}

.word {
  padding: 0px 2em;
  background-color: #fff;
  max-width: 800px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.05);
  font-family: "Rubik";
}

.word h4,
.word input {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.title {
  font-size: 2em;
  font-weight: 600;
  margin: 20px 0px 0px 0px;
  display: flex;
  align-items: center;
}

.titleAlone {
  font-size: 2em;
  font-weight: 600;
  margin: 20px 0px;
  display: flex;
  align-items: center;
}

.word span {
  padding: 0px 20px;
}

.info {
  font-weight: 200;
}

.word i {
  color: var(--primary);
  cursor: pointer;
}

.hbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hbox .info {
  font-size: 0.8em;
  margin: 0em 0.8em;
}

.word .inputTitle {
  font-size: 2em;
  font-weight: 600;
  margin: 20px 0px 0px 0px;
  height: 40px;
}

.word .inputTitleError {
  font-size: 2em;
  font-weight: 600;
  margin: 20px 0px 0px 0px;
  border-bottom: 2px solid var(--primary);
  background-color: rgba(255, 65, 65, 0.067);
  height: 40px;
}

.word textarea {
  border: none;
  border-radius: 5px;
  resize: none;
  height: 20px;
  margin: 1em 0px;
}

.word textarea:focus {
  outline: none;
  border-bottom: 2px solid var(--secondary);
}

.word .inputInfo {
  font-weight: 200;
}

.word .inputInfoError {
  font-weight: 200;
  border-bottom: 2px solid var(--primary);
  background-color: rgba(255, 65, 65, 0.067);
}

.add {
  display: flex;
  justify-content: center;
  margin-top: 1em;
}

.add i {
  color: #008e3d;
  font-size: 50px;
  cursor: pointer;
}

.audioContainer {
  overflow: hidden;
  margin: 15px auto;
  max-width: 736px;
}
