.card {
  padding: 4em;
  margin: auto;
  background: seashell;
  position: relative;
  min-width: fit-content;
  width: 30%;
}

.forward {
  background-color: transparent;
  border: none;
  position: absolute;
  bottom: 2em;
  right: 3em;
  cursor: pointer;
}

.backward {
  background-color: transparent;
  border: none;
  position: absolute;
  bottom: 2em;
  left: 3em;
  cursor: pointer;
}

.wrong {
  padding: 1em 0em;
}

.count {
  position: absolute;
  bottom: 2em;
  left: 50%;
  cursor: pointer;
}

.audioContainer {
  overflow: hidden;
  margin: 15px auto;
  max-width: 736px;
}
