@import url("https://fonts.googleapis.com/css2?family=Press+Start+2P&family=Roboto:wght@100;300;400;700&family=Rubik:wght@400;500;700&display=swap");

:root {
  --primary: #d62246;
  --secondary: #0c6291;
}

body {
  background: #f1f1f1;
  margin: 0;
  font-family: "Rubik";
  box-sizing: border-box;
}

input {
  font-family: "Rubik";
}

button {
  font-family: "Rubik";
}

.audio-symbol {
  cursor: pointer;
}

.error {
  color: var(--primary);
}

hr {
  background-color: var(--secondary);
  width: 20%;
  padding: 2px;
  margin: 2em 0em;
}

.container {
  padding: 4em;
}

.back {
  background-color: var(--secondary);
  border: none;
  padding: 1em;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}
