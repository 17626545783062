:root {
  --primary: #d62246;
  --secondary: #0c6291;
}

.wordlistDetails {
  padding: 2em;
  background-color: #fff;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0.2em;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.05);
}

.title {
  font-size: 1.4em;
  font-weight: 500;
  color: var(--secondary);
}

.info {
  font-size: 0.8em;
  font-weight: 100;
  color: grey;
}

.link {
  text-decoration: none;
  min-width: 20em;
}
