:root {
  --primary: #d62246;
  --secondary: #0c6291;
  --secondaryLight: #0c639125;
}

.words {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 2em;
}

.words form {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 0em;
}

input {
  padding: 0.5em;
  background-color: transparent;
  border: none;
  font-size: 1em;
  border-bottom: 2px solid var(--secondary);
  border-radius: 5px;
}

input:focus {
  outline: none;
  background-color: var(--secondaryLight);
  -webkit-transition: background-color 100ms linear;
  -ms-transition: background-color 100ms linear;
  transition: background-color 100ms linear;
}

li {
  list-style-type: none; /* Remove bullets */
  padding: 0.4em; /* Remove padding */
  margin: 0; /* Remove margins */
  display: flex;
  gap: 0.5em;
  align-items: center;
}

i {
  color: var(--primary);
  cursor: pointer;
}

.deleteBtn {
  border: none;
  background-color: var(--primary);
  padding: 1em;
  color: white;
  font-size: 1.2em;
  cursor: pointer;
}

.editingTitle {
  padding: 0em;
  width: 40%;
}

.error {
  padding: 0.5em;
  background-color: rgba(255, 145, 145, 0.464);
  border: 2px solid var(--primary);
  border-radius: 4px;
  color: var(--primary);
}
