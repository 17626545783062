:root {
  --primary: #d62246;
  --secondary: #0c6291;
}

.title {
  color: var(--primary);
  padding: 2rem;
  margin: 0px;
  font-size: 2.5rem;
}

.link {
  text-decoration: none;
}

header {
  display: flex;
  background-color: #fff;
  align-items: center;
  justify-content: space-between;
}

nav .link {
  padding: 1em;
  background-color: var(--secondary);
  color: #fff;
  margin: 0em 1em;
  border-radius: 4px;
}

.logout {
  padding: 1em;
  border: none;
  background-color: var(--primary);
  color: #fff;
  margin: 0em 1em;
  border-radius: 4px;
  cursor: pointer;
}
